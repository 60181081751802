.circle-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid $black;
    border-radius: 50%;
    padding-top: 5px;

    &.gallery-link {
        position: absolute;
        top: 0.875rem;
        left: 0.875rem;
        border: 0;
        background-color: rgba(255,255,255,0.72);
    }
}

.social-icons {
    .social-icons-link {
        display: inline-block;
        margin-right: 0.5rem;
        text-decoration: none;
    }

    .circle-wrap {
        width: 45px;
        height: 45px;
    }

    i {
        font-size: 2rem;
        line-height: 1;
    }

    [class^='icon-']::before, [class*=' icon-']::before {
        margin: 0;
    }
}

footer {
    .social-icons {
        margin-bottom: 1rem;

        .social-icons-link {
            margin-right: 1rem;
        }
    }
}

.sales-site {
    .social-icons {
        margin-top: 1rem;
    }
}
