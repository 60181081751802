.solr-pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 4rem;

    ul.pagination {
        align-items: center;
        justify-content: center;

        a {
            color: $gray-darker;
        }

        .page-item {
            color: $gray-darker;

            .page-link {
                margin-right: 4px;
                margin-left: 4px;
                padding: 0.15rem 0.6rem 0.2rem 0.6rem;
            }

            a.page-link {
                &:hover,
                &:focus {
                    border-radius: 50%;
                    color: $black;
                    background-color: $gray-light;
                }
            }

            &.active {
                .page-link {
                    z-index: 0;
                    border-radius: 50%;
                    color: $white;
                    background-color: $black;
                }
            }
        }
    }
}
