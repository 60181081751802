.highlight-box {
    padding: 1.3rem;

    &.highlight-box-information {
        color: $white;
        background-color: $color-information;
    }
}

.product-header {
    @extend .row;
    @extend .g-0;
    @extend .mx-0;

    @include media-breakpoint-up(md) {
        background-color: $color-highlight;
    }

    .product-image {
        @extend .col-12;
        @extend .col-md-6;
        @extend .order-2;
        @extend .order-md-1;
        @extend .justify-content-center;

        display: flex;
        flex-direction: column;
    }

    .product-highlights {
        @extend .col-12;
        @extend .col-md-6;
        @extend .order-1;
        @extend .order-md-2;
        @extend .justify-content-center;

        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            .content-wrapper {
                padding: 1rem 1.33rem;
            }
        }
    }

    .product-type {
        font-size: $small-font-size;
        font-weight: $font-weight-bold;
    }

    .btn-outline-black {
        margin-top: 1rem;
    }
}

.search-result {
    margin-bottom: 3rem;

    a {
        display: block;
        text-decoration: none;
    }

    .col-left {
        @extend .col-12;
        @extend .col-md-5;
    }

    .col-right {
        @extend .col-12;
        @extend .col-md-7;

        &.col-travelogue-data {
            padding: 2.625rem 1.5rem;
            color: $white;
            background-color: $color-elsewhere;
        }
    }

    .product-top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray-light;

        .favorite-icon {
            display: none;
        }

        .rating {
            margin-bottom: 0.5rem;
        }
    }

    .product-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-light;
        padding: 0.75rem 0;
    }

    .product-price {
        span {
            display: block;

            &.price {
                font-family: $font-family-serif;
                font-size: 24px;
                line-height: 36px;
            }

            &.price-unit {
                color: $gray-dark;
                font-size: $small-font-size;
            }
        }
    }

    .product-morelink {
        .btn {
            margin-left: 0.5rem;
            padding: 1rem 1.5rem;
            white-space: nowrap;
        }
    }

    .product-type {
        margin-bottom: 6px;
        color: $text-muted;
        font-size: $small-font-size;
    }

    .product-data {
        ul {
            margin-bottom: 1rem;
            list-style: disc;
        }
    }

    &.search-result--itinerary {
        .col-left {
            position: relative;

            .image-overlay-wrap {
                position: relative;
            }

            .itinerary-map {
                position: absolute;
                right: 0;
                bottom: 2rem;
                left: 0;
                text-align: center;

                span.lightbox-view {
                    border-radius: 16px;
                    padding: 0.25rem 0.75rem;
                    background: $white;
                }
            }
        }
    }

    &.search-result--travelogue {
        .col-left {
            min-height: 200px;
            background-size: cover;

            @include media-breakpoint-up(sm) {
                min-height: 300px;
            }

            @include media-breakpoint-up(md) {
                min-height: inherit;
            }
        }
    }
}

.product-characteristics {
    margin-top: 1rem;

    .characteristic-title {
        margin-bottom: 6px;
        border-top: 1px solid $gray-light;
        padding-top: 1rem;
        font-size: $small-font-size;
        font-weight: $font-weight-bold;

        &.no-border-top {
            border-top: 0;
            padding-top: 0;
        }
    }
}

.content-block {
    .expand-content {
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;

        &::before {
            padding-right: 0.25rem;
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconkuoni';
            font-weight: normal;
            vertical-align: bottom;
            content: '\e90e';
        }
    }

    .content-ellipsis {
        position: relative;
        height: 300px;
        margin-bottom: 1rem;
        overflow-y: hidden;
        text-overflow: ellipsis;

        &::before {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            height: 90px;
            background: linear-gradient(transparent, #fff);
            content: ' ';
        }
    }

    &.expanded {
        .expand-content {
            display: none;

            &::before {
                content: '\e90f';
            }
        }

        .content-ellipsis {
            height: auto;
            overflow-y: unset;

            &::before {
                display: none;
            }
        }
    }

    /*
    ul + br,
    ul + br + br,
    br + br + br {
        display: none;
    }
    */
}

.card {
    &.card-product,
    &.card-offer {
        display: flex;

        .card-img-top {
            max-width: 100%;
            height: auto;
        }

        .card-img-bg {
            position: relative;
            width: 100%;
            height: auto;

            &::before {
                display: block;
                width: 100%;
                padding-top: 66%;
                content: ' ';
            }

            .card-img-bg-content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-position: center center;
                background-size: cover;
            }
        }

        .card-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;

            .pricing {
                .price {
                    font-family: $font-family-serif;
                    font-size: 1.5rem;
                    line-height: 2.25rem;
                }
            }
        }
    }

    &.card-offer {
        .card-footer {
            margin-bottom: 1.5rem;
        }
    }
}

.row-offer {
    flex-flow: wrap;
    border-bottom: 1px solid $gray-light;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(sm) {
        flex-flow: nowrap;
    }

    .col-offer {
        flex: 2 1 auto;
        width: 100%;

        @include media-breakpoint-up(sm) {
            flex: 1 1 auto;
            width: auto;
        }

        > div {
            margin-right: 1rem;
        }

        .offer-region {
            font-family: $font-family-serif;
            font-size: 1.25rem;
        }
    }

    .col-offer-price,
    .col-more-link {
        flex: 1 0 auto;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
        }
    }

    .col-offer-price {
        font-family: $font-family-serif;
        font-size: 1.25rem;

        span {
            margin-right: 1.5rem;
        }
    }
}

.online-offers {
    .all-offers-link {
        margin: 1rem 0;
    }

    .no-offers-msg {
        @extend .text-muted;
        @extend .small;
        font-style: italic;
    }
}

.more-images {
    margin-top: 0.5rem;
    a {
        text-decoration: none;
    }
}